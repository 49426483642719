// extracted by mini-css-extract-plugin
export var MuiOutlinedInputNotchedOutline = "styles-module--MuiOutlinedInput-notchedOutline--4GDEY";
export var MuiSelectSelect = "styles-module--MuiSelect-select--a7xys";
export var aaa = "styles-module--aaa---tMJk";
export var antUploadText = "styles-module--antUploadText--eSgaG";
export var css11u53oeMuiSelectSelectMuiInputBaseInputMuiOutlinedInputInput = "styles-module--css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input--TKa2I";
export var cssJedpe8MuiSelectSelectMuiInputBaseInputMuiOutlinedInputInput = "styles-module--css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input--Pb9V4";
export var divvv = "styles-module--divvv---nDi1";
export var draggerInbox = "styles-module--draggerInbox--XyD9M";
export var fbutton = "styles-module--fbutton--IQ2Qa";
export var form_control = "styles-module--form_control--GdDu6";
export var form_control1 = "styles-module--form_control1--ags0d";
export var formlabels = "styles-module--formlabels--bkLJF";
export var get = "styles-module--get--zUEKw";
export var myForm = "styles-module--myForm--KRz95";
export var primary = "\"abc\"";
export var secondary = "\"dec\"";
export var setUploadBtn = "styles-module--setUploadBtn--WzmqH";
export var uploadMainDiv = "styles-module--uploadMainDiv--RtYpl";